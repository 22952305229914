import React from 'react';
import fondoPortada from '../images/fondoPortada.png';
import '../styles/main.css';
class Portada extends React.Component{
    render(){
        return(
            <div className="">
                <div className="carousel-inner portada">
                    <img className="fondoPortada" src={fondoPortada} alt="" />
                    <div className="container">
                    <div className="carousel-caption">
                        <h1>Directorio Verde de Guatemala</h1>
                        <p>Encuentra opciones verdes en todas sus categorías disponibles en todo Guate</p>
                        {/* <p><a className="btn btn-lg btn-primary" href="#">Learn more</a></p> */}
                    </div>
                    </div>
                </div>
            </div>
        );        
    }
}
export default Portada;

//<svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"></rect></svg>