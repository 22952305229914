import React from 'react';

function PageError (props){
    console.log("eerror");
    console.log("eerror"+props.error);
    return (
        <div className="PageError">
            {props.error.message}
        </div>
    )
   

    
}
export default PageError;