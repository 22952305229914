import React from 'react';
import NavBar from './NavBar';
import Portada from './Portada';
import Footer from './Footer';

function Layout (props){
    return(
        <React.Fragment>
           <NavBar />
            <Portada />
                {props.children}
            <Footer />
        </React.Fragment>
    )   
}

export default Layout;