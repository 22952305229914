import React from 'react';
import '../styles/main.css';
import api from '../api';
import constantes from '../components/constantes/constantes.json'
import CardEmpresa from '../components/CardEmpresa';

class BotonFiltro extends React.Component{

    constructor(props){
        super(props);
        this.state={
            loading: true,
            error: null,
            dataCategorias:undefined,
            categoria: this.props.categoria,
            departamento: this.props.departamento,
            empresas:"inicio",
        }
     // this.empresa = [];
    }

    componentDidMount(){
        this.fetchDataCategorias();   
        this.fetchEmpresa(0,0);

    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.categoria !== this.state.categoria){
            this.fetchEmpresa(this.state.categoria,this.state.departamento);
           
        }
        if(prevState.departamento !== this.state.departamento){
            this.fetchEmpresa(this.state.categoria,this.state.departamento);
        }
       
    }

    fetchDataCategorias= async () => {
        this.setState({loading: true, error: null});

        try{
            const dataC = await api.categorias.list();
            this.setState({ loading:false, dataCategorias: dataC});          
        }catch(error){
            this.setState({ loading:true, error: error});
        } 
    }

    fetchEmpresa = async (categoria, departamento) => {
        //this.setStateEmp({loading: true, error: null});
        /*let categoria = this.stateItems.Cat;
        let depto = this.stateItems.Depto;*/
        console.log("cat " + categoria+"dep " +departamento);
        try{
            const dataE = await api.empresas.read(categoria,departamento);
            console.log("tamañoDataE " + dataE.length);
            if(dataE.length!=undefined){
                this.setState({
                    empresas:dataE,
                })
            }else{
                this.setState({
                    empresas:[],
                })
            }

            
            //this.setStateEmp({ loading:false, dataEmpresa: dataE});
            
        }catch(error){
           // this.setStateEmp({ loading:false, error: error});
        }
    }

    handleChange= e =>{
        console.log("handel ");
        console.log({
            name: e.target.name,
            value: e.target.value});

            if(e.target.name=="categoria"){  
                
                this.setState({
                    categoria:e.target.value,
                })
            }
            if(e.target.name=="departamento"){
                this.setState({
                    departamento:e.target.value,
                })
            }
            console.log("handelsdep "+ this.props.departamento + " " + this.state.departamento);
    } 
    

    render(){     
        const departamentos = constantes.departamentos;
        
        
            if(this.state.loading === true){                                
                return(
                    <React.Fragment>
                         <form>
                        <div className="row">
                        <div className="form-group col-6 ">
                            <label htmlFor="">Categorías</label>
                            <select className="botonFiltro" id="Dep">
                                <option value="0">Todos</option>  
                                    <option>Todos</option>                                                      
                            </select>
                            </div>
                            <div className="form-group  col-6 ">
                                <label htmlFor="">Departamentos</label>
                                <select className="botonFiltro" id="Dep">
                                    <option value="0">Todos</option>  
                                        <option>Todos</option>                                                      
                                </select>
                            </div> 
                        </div>            
                    </form>  
                    <div className="">
                        <div className="mt-3">
                            <div className="">
                                <CardEmpresa empresa={this.state.empresas} />
                            </div>
                            
                        </div>               
                    </div>
                    </React.Fragment>
                           
                )              
            } 
        
          
            return(                 

                <React.Fragment>
                     <form onChange={this.handleChange} >
                     <div className="row">
                     <div className="form-group col-6 ">
                        <label htmlFor="">Categorías</label>
                        <select value={this.state.value}  className="botonFiltro form-select " id="Cat"  name="categoria">
                            <option value="0">Todos</option>
                            {
                                this.state.dataCategorias.map((elemento) =>(       
                                    <option key={elemento.id} value={elemento.id}>{elemento.nombreCatG} </option>                        
                                ))     
                            } 
                        </select>
                        </div>   
                        <div className="form-group  col-6 ">
                             <label htmlFor="">Departamentos</label>
                            <select value={this.state.value}  className="botonFiltro" id="Dep" name="departamento">
                                <option className="mr-2 ml-2" value="0">Todos</option>
                                {
                                    departamentos.map((elementoDepto) =>(            
                                        <option key={"d"+elementoDepto.id} value={elementoDepto.id}  className="mr-2 ml-2" >{elementoDepto.nombreCatG} </option>                        
                                    ))     
                                } 
                            </select>
                        </div>  
                     </div>                
                </form>
                    <div className="">
                        <div className="mt-3">
                            <div className="">
                                <CardEmpresa empresa={this.state.empresas} />
                            </div>
                            
                        </div>               
                    </div>
                </React.Fragment>        
            );
        }
}

export default BotonFiltro;

//onChange={(e)=>{this.stateItems.Cat=e.target.value; this.fetchEmpresa();}}
//