import React from 'react';
import BotonFiltro from '../components/BotonFiltro';
import CardEmpresa from '../components/CardEmpresa';
import PageLoading from '../components/PageLoading';
import PageError from '../components/PageError';
import api from '../api';

import buscar from '../images/buscar.png';

class Home extends React.Component{

    //inicializar estado
    //ocurre primero
    constructor(props){
        super(props);
        this. state={
            loading: true,
            error: null,
            data:undefined,
        }
    }

    componentDidMount(){
        //this.fetchData(0,0);
    }

    //cuando ocurre un actualizacion
    componentDidUpdate(prevProps, prevState){
        console.log();
    }

    //cuando sale de escena
    componentWillUnmount(){
        clearTimeout(this.timeoudId);
    }

    fetchData = async (categoria, depto) => {
        this.setState({loading: true, error: null});

        try{
            const data = await api.empresas.read(categoria,depto);
            this.setState({ loading:false, data: data});
        }catch(error){
            this.setState({ loading:false, error: error});
        }
    }


    render(){
        console.log(this.state);
        // if(this.state.loading === true){
        //     return(
        //         <div>        
        //             <div className="directorio pb-5">
        //                 <h5 className="pt-5 text-center">Busca opciones verdes en todo Guate</h5>
        //                 <div className="container">
        //                     <div className="">
        //                         <BotonFiltro/>                        
        //                     </div>
        //                     <div className="">
        //                         <PageLoading />             
        //                     </div>

                        
        //                 </div>
        //             </div>           
        //         </div>
        //     )         
        // }
        // if(this.state.error!==null){
        //     console.log("si error");
        //     return<PageError error = {this.state.error}/>;        
        //  }

        return(
         <div>        
            <div className="directorio pb-5">
                <h5 className="pt-5 text-center">Busca opciones verdes en todo Guate</h5>
                <div className="container">
                    <div className="">
                        <BotonFiltro categoria='0' departamento = '0'/>                        
                    </div>
                    {/* <div className="">
                        <div className="mt-3">
                            <div className="">
                                <CardEmpresa empresa={this.state.data} />
                            </div>
                            
                        </div>               
                    </div> */}

                  
                </div>
              </div>           
         </div>
         
        );
    }
}
export default Home;