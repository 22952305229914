import React from 'react';
import webGris from '../images/icons/webGris.png';
import fbGris from '../images/icons/fbGris.png';
import whatsGris from '../images/icons/whatsGris.png';
import instaGris from '../images/icons/instaGris.png';
import iconoCompra from '../images/icons/iconoCompra.png';

import buscar from '../images/buscar.png';
import load from '../images/load.png';
import '../styles/main.css';

class CardEmpresaItem extends React.Component{
    render(){
        const web = this.props.empresa.web;
        const whats = this.props.empresa.whats;
        return(
           <div className="card p-4" >
                <div className="row mb-4 ">
                    <div className="col-8 col-md-12 text-start d-md-flex p-md-0">
                        <div className="col-md-6 text-left mb-0 d-flex">
                            <h4 className="col-md-8 p-0">{this.props.empresa.nombreCatG}</h4>
                            <div className="col-md-4 pl-0 dp-sm-none ">
                                <img className="emoji" src={iconoCompra} alt="" />
                            </div>                           
                        </div>  
                        <div className="col-md-6">
                            <h4 className="text-left text-md-right pr-0 ">{this.props.empresa.nombre}</h4>
                        </div>                      
                        
                    </div>
                    <div className="col-4 dp-md-none text-end">
                         <img className="emoji" src={iconoCompra} alt="" />
                    </div>                   
                </div>
                <div className="row ">
                    <div className="col col-md-6 text-center ">
                        <img  variant="top" src={this.props.empresa.logo} className="card-img-top  imgCard" />
                    </div>
                    <div className="col col-md-6 mt-4 mt-md-0">
                        <h5 className="cardTitle">{this.props.empresa.nombreEmpresaG}</h5>
                        <p className="card-text mt-3 mb-3">{this.props.empresa.descripcion} </p>
                        <div id="redesSocialesCard" className="text-center text-md-right ">
                            <a href={this.props.empresa.fb} target="_blank"><img className="iconCard" src={fbGris}/></a>
                            <a href={this.props.empresa.insta} target="_blank"><img className="iconCard" src={instaGris} alt=""  /></a>
                            {whats != null &&
                                <a href="" target="_blank"><img className="iconCard" src={whatsGris} alt="" /></a>
                            }
                            {web != null &&
                                <a href={this.props.empresa.web} target="_blank"><img className="iconCard" src={webGris} alt="" /></a> 
                            }                           
                        
                        </div>                    
                    </div>                    
                </div>               
            </div>          
        );
    }
}

function CardEmpresa(props){
    const empresas = props.empresas;
    console.log('tamaño '+props.empresa.length);
        if(props.empresa.length === 0){
            return(
                <div className="text-center noResultados">
                    <img src={buscar} alt="" />
                    <h3>Pronto tendremos opciones verdes para ésta busqueda</h3>     
                    <p>intenta de nuevo con otra categoría o departamento</p>           
                </div> 
            )
        }
        if(props.empresa == "inicio"){
            return(
                <div className="text-center noResultados">
                    <img src={load} alt="" />
                    <h3>¡Gracias por ser parte del cambio!</h3>     
                    <p>Cargando ...</p>           
                </div> 
            )
        }
        return(
            <div className="row">
                {props.empresa.map(empresa => {
                return (
                    <div className="col-sm-12 col-md-6">
                        <CardEmpresaItem empresa={empresa} />
                    </div>

                    
                );
              })}
          </div>
        )
    
}


export default CardEmpresa;
