import React from 'react';
import '../styles/main.css';
import whatsapp from '../images/icons/whatsapp.png';
import insta from '../images/icons/insta.png';
import fb from '../images/icons/fb.png';
import twitter from '../images/icons/twitter.png';
import logoFooter from '../images/logoFooter.jpg';
import { Link } from 'react-router-dom';

class Footer extends React.Component{
    render(){
        return(
            <div className="footer" id="footer">
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-sm-12 col-md-4 ">
                            <a href="#navBar"><img className="logo" src={logoFooter} alt="" /></a>

                        </div>
                        <div className="col-sm-12 col-md-4 text-footer">
                            <h6>Divegua Empresarial</h6>
                            <a href="">Postula tu empresa verde</a><br />
                            <a href="https://forms.gle/rSVKVvzedEKuy13r5" target="_blank">haz click aquí</a>
                           
                        </div>                      
                        <div className="col-sm-12 col-md-4">
                            <h6>Síguenos</h6>
                            <div>
                                <a href="https://www.facebook.com/divegua" target="_blank"><img className="mr-3" src={fb} alt="" /></a>
                                <a href="https://www.instagram.com/divegua/" target="_blank"><img className="mr-3" src={insta} alt="" /></a>
                                <a href="https://twitter.com/verde_bit" target="_blank"></a><img className="mr-3" src={twitter} alt="" />
                                <a href="https://wa.link/n2i0dt" target="_blank"><img className="mr-3" src={whatsapp} alt="" /></a>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>                
                <div className="mt-5 pb-3 madeIn">
                    <p className="text-center text-footer">2021 por <a href="https://www.bitplanetaverde.com/">@BitPlanetaVerde</a></p>
                </div>

            </div>
           

        );
    }
}

export default Footer;