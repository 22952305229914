import React from 'react';
import logoDivegua from '../images/icons/diveguaicono.png';
import { Navbar, Nav, Container } from 'react-bootstrap';
import {Link} from 'react-router-dom';

class NavBar extends React.Component{
    render(){
        return(
            <Navbar expand="lg" variant="dark"  id="navBar">
            <Navbar.Brand href="/" ><img className="brandIcono" src={logoDivegua} alt=""/></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="center menu">
                <Link className="link" to=""><img src={logoDivegua} alt="" /></Link>
                {/* <Link className="link" to="/ss">Sobre Divegua</Link> */}
                <Nav.Link href="#footer">Contacto</Nav.Link>
                <Link className="link" to="EmpresaVerde">¿Eres Empresa Verde?</Link>
                {/* <Link className="link" to="#link">Donaciones</Link> */}
             </Nav>
            </Navbar.Collapse>
          </Navbar>

        );
    }
}
export default NavBar;