import React from 'react';
import { Link } from 'react-router-dom';
import postulate from '../images/postulate1.png';

function EmpresaVerde(){
    return (
        <div className="empresasVerdes p-5 row">
            <h2 className="col-12 text-center ">Sé parte del Directorio Verde</h2>
            <div className=" col-12 col-md-6 p-md-5">
                <p >
                    Divegua pretende colocar en un solo espacio a todas las empresas u organizaciones de Guatemala, que contribuyen 
                    con el planeta.
                    <br/>
                    <br/>

                    Ser parte del Directorio es gratuito, además de aparecer en ésta pagina, también tendrás un espacio en nuestras redes
                    sociales. 


                    <br/>
                    <br/>
                    En instagram y facebook nos encuentras como @Divegua

                    
                    <br/>
                    <br/>


                    Si eres empresa, ong o emprendimiento que tiene productos o servicios que ayudan al planeta,
                    puedes aplicar al directorio  </p>
                    <a href="https://forms.gle/rSVKVvzedEKuy13r5" target="_blank" className="mb-5 text-Brillante font-weight-bold">Haciendo click aqui</a>
             
            </div>
           <div className="col-12 col-md-6 p-md-5">
        
                <img src={postulate} alt="" />
           </div>
            
        </div>  
       )  
}

export default EmpresaVerde;