import React from 'react';
import { Link } from 'react-router-dom';
import imagen404 from '../images/404.png';

function NotFound(){
    return (
        <div className="text-center notFound pb-3">
            <img src={imagen404} alt="" />
            <p>ésta página ya no existe pero puedes regresar al inicio</p>
            <Link to="" className="mb-5"> Regrésame al Inicio</Link>
        </div>  
       )  
}

export default NotFound;